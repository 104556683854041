// @ts-strict-ignore
import React from "react"

import { lineString } from "@turf/helpers"
import length from "@turf/length"
import { addHours, parseISO } from "date-fns"
import { graphql } from "gatsby"
import styled from "styled-components"

import Stack from "@kiwicom/orbit-components/lib/Stack"
import Table, {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@kiwicom/orbit-components/lib/Table"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import Hero from "components/hero"
import Layout from "components/layout"
import { RouteMap } from "components/route-map"

import {
  formatAsAbbreviatedDateWithYear,
  getDefaultTimezone,
} from "utils/date-utils"

const RouteMapContainer = styled.div`
  display: flex;
  height: 70vh;
  width: 100%;
`

const RouteMapTemplate = ({ data, pageContext }) => {
  let asOf: Date

  if (typeof window !== "undefined") {
    const params = new URLSearchParams(window.location.search)
    // Generally think about days starting at 4am
    asOf = addHours(
      params.has("date") ? parseISO(params.get("date")) : new Date(),
      4
    )
  }

  const direction = pageContext.direction // Passed through on page creation
  const service = data.serviceYaml

  const currentRoutes = service.routes.find(
    (route) => asOf < parseISO(route.to) || route.to == null
  )
  const route =
    direction == "outbound" ? currentRoutes.outbound : currentRoutes.return
  const stopPattern = route.stopPattern
  const paths = data.allPathYaml.nodes
  const fullRoute = []
  const segments = []

  for (const [index, stopPatternItem] of stopPattern.entries()) {
    const stop = stopPatternItem.stop
    fullRoute.push(stop.coordinate)
    const nextStopPatternItem = stopPattern[index + 1]
    if (nextStopPatternItem) {
      const nextStop = nextStopPatternItem.stop
      const routeToNextStop = paths.find(
        (x) =>
          x.origin.stopId === stop.stopId &&
          x.destination.stopId === nextStop.stopId
      )

      if (routeToNextStop) {
        fullRoute.push(...routeToNextStop.coordinates)
      }

      const segment = {
        origin: stopPatternItem,
        destination: nextStopPatternItem,
        straightLineLength: length(
          lineString([stop.coordinate, nextStop.coordinate])
        ),
      }

      segments.push(segment)
    }
  }

  const origin = direction == "outbound" ? service.origin : service.destination
  const destination =
    direction == "outbound" ? service.destination : service.origin

  const title = () =>
    origin +
    " to " +
    destination +
    (service.via != null ? ` (Via ${service.via})` : "")

  const introSentence = `This service calls at ${
    stopPattern.length - 2
  } stops on the way
  from ${origin} to ${destination}.`

  const numberPreBookedStops = stopPattern.filter(
    (stop) => stop.preBooked == true
  ).length
  const preBookedStopsSentence =
    numberPreBookedStops > 0
      ? numberPreBookedStops == stopPattern.length - 2
        ? "All intermediate stops require pre-booking."
        : `This includes ${numberPreBookedStops} pre-booked stops.`
      : ""

  const servicesPerDaySentence =
    currentRoutes.typicalTripsPerDayPerDirection != 0
      ? `There are usually ${currentRoutes.typicalTripsPerDayPerDirection} services per day in each direction.`
      : ""

  return (
    <Layout title={title()}>
      <Hero
        title={title()}
        subtitle={`Route ${route.serviceNumber.toUpperCase()} ${
          direction == "outbound" ? "Outbound" : "Inbound"
        } (as of ${formatAsAbbreviatedDateWithYear(
          asOf,
          getDefaultTimezone()
        )})`}
      />
      <Stack direction="column" align="center" spacing="XXLarge">
        <Container size="medium">
          <Text align="center">
            {introSentence} {preBookedStopsSentence} {servicesPerDaySentence}{" "}
            {currentRoutes?.description}
          </Text>
        </Container>
        <RouteMapContainer>
          <RouteMap
            showService={{ id: service.serviceId, direction: direction }}
            fullStopData={true}
            as_of={asOf?.toISOString()}
          />
        </RouteMapContainer>
        <Container size="medium">
          <h2>Distances</h2>
          <Table striped={false}>
            <TableHead>
              <TableRow>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell>Straight Line Distance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {segments.map((segment) => (
                <TableRow key={segment.origin.stop.name}>
                  <TableCell>{segment.origin.stop.name}</TableCell>
                  <TableCell>{segment.destination.stop.name}</TableCell>
                  <TableCell>
                    {segment.straightLineLength.toFixed(1)}&nbsp;km
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Container>
      </Stack>
    </Layout>
  )
}

export default RouteMapTemplate

export const query = graphql`
  query ($serviceId: String!) {
    serviceYaml(serviceId: { eq: $serviceId }) {
      serviceId
      origin
      destination
      via
      routes {
        title
        to
        typicalTripsPerDayPerDirection
        description
        outbound {
          serviceNumber
          stopPattern {
            stop {
              stopId
              name
              coordinate
            }
            preBooked
            cutOffMins
            routeToNextStopVersion
          }
        }
        return {
          serviceNumber
          stopPattern {
            stop {
              stopId
              name
              coordinate
            }
            preBooked
            cutOffMins
          }
        }
      }
    }
    allPathYaml {
      nodes {
        origin {
          stopId
        }
        destination {
          stopId
        }
        coordinates
      }
    }
  }
`
